/* src/modules/chatbotLiveChat/ChatBot.css */

/* Base adjustments for modal */
.main {
  width: 25vw;
  height: 40vh;
  margin: 0 auto;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 600px) {
  .main {
    width: 70vw;
  }
}

h2 {
  margin-top: 10px;
  color: aliceblue;
  font-size: 1em;
  padding: 5px;
  text-align: center;
}

/* Chatbox styling for modal */
.chatbox {
  flex: 1;
  width: 90%; /* Reduced width for better fit in modal */
  height: 50vh; /* Reduced height for modal */
  color: aliceblue;
  padding: 10px;
  overflow-y: scroll;
  font-size: 1em;
  font-family: "Atma";
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
}

.message-container {
  white-space: pre-line;
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  width: 100%;
}

.messages {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  flex-direction: column;
}

.thinking-box {
  /* height: 20px; */
  display: flex;
  width: 90%; /* Adjusted for modal */
  justify-content: left;
  margin-bottom: 10px;
}

.thinking {
  font-size: 0.9em; /* Reduced font size */
  color: #c796e7;
  font-weight: bold;
}

/* Input and send button styling */

.input-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.input {
  font-family: "Atma";
  font-weight: bold;
  background-color: #000;
  height: 35px;
  width: 85%;
  border-radius: 20px;
  border: 0px solid #ccc;
  color: aliceblue;
  box-shadow: 3px 3px 3px rgb(20, 20, 20);
  margin-right: 5px;
  padding: 8px;
}

.send-btn {
  background-color: #007bff;
  border: none;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 3px 3px 3px rgb(20, 20, 20);
}

/* Option buttons for better fit in modal */
.btn-container {
  margin-top: 15px;
  display: flex;
  justify-content: center; /* Center align for modal */
  width: 90%; /* Adjusted for modal */
}

.option-btn,
.option-btn-selected {
  width: 25%;
  padding: 8px;
  font-size: 0.9em;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin: 0 5px;
}

.option-btn {
  background-color: #7dbafc;
  color: white;
}

.option-btn-selected {
  background-color: #0379f7;
  color: white;
  box-shadow: 3px 3px 3px rgb(20, 20, 20);
}

.option-btn:hover,
.option-btn-selected:hover {
  border: 1px solid #000000;
  background-color: #0379f7;
  box-shadow: 3px 3px 3px rgb(20, 20, 20);
}
