/* MainNavigation.css */

.main-navigation__menu-btn {
  width: 1rem;
  height: 2rem;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 2rem;
  cursor: pointer;
}

.main-navigation__menu-btn span {
  display: block;
  width: 2rem;
  height: 3px;
  background: white;
}

.main-navigation__title {
  color: white;
}

.main-navigation__title a {
  text-decoration: none;
  color: white;
}

.main-navigation__header-nav {
  display: none;
}

.main-navigation__drawer-nav {
  height: 100%;
}

@media (min-width: 768px) {
  .main-navigation__menu-btn {
    display: none;
  }

  .main-navigation__header-nav {
    display: block;
  }
}

.main-navigation__title-link {
  font-family: "Atma", sans-serif;
  font-size: 2.5rem;
  text-shadow: 3px 3px 2px rgba(0, 0, 0, 0.5);
  color: var(
    --link-color,
    #000
  ); /* Fallback color in case theme isn’t applied */
  white-space: nowrap; /* Prevents text from wrapping */
}

/* Adjust font size for medium screens */
@media (max-width: 768px) {
  .main-navigation__title-link {
    font-size: 3rem;
  }
}

/* Further adjust font size for smaller screens */
@media (max-width: 480px) {
  .main-navigation__title-link {
    font-size: 1.8rem;
  }
}
