/* Base chat interface styling */
.chat-interface {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #f9f9f9; /* Light background for the chat interface */
  border: 1px solid #eaeaea; /* Light border for a subtle outline */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

/* Chat history area */
.chat-history {
  flex: 1;
  max-height: 90%;
  overflow-y: auto;
  padding: 20px; /* Increased padding for more space */
  padding-bottom: 120px; /* Increased bottom padding to create a gap */
  background-color: #333333; /* White background for contrast */
}

/* Chat input area */
.chat-input {
  display: flex;
  align-items: center;
  padding: 20px; /* More padding for better spacing */
  background-color: #fafafa; /* Slightly different background to distinguish input area */
  position: fixed; /* Keep input area fixed at the bottom */
  bottom: 0; /* Align to the bottom of the viewport */
  left: 0; /* Align to the left of the viewport */
  width: 100%; /* Ensure it spans the full width */
  box-sizing: border-box; /* Include padding in the element's total width */
}

/* Input field styling */
.chat-input textarea {
  flex: 1;
  padding: 12px; /* More padding for a larger touch area */
  border: 2px solid #007bff; /* Blue border for visual appeal */
  border-radius: 20px; /* Rounded corners for a modern look */
  resize: none; /* Disable resizing to maintain layout */
  margin-right: 10px; /* Space between input field and send button */
}

/* Send button styling */
.chat-input button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 12px 20px; /* Larger padding for a more clickable area */
  border-radius: 20px; /* Rounded corners to match input field */
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.chat-input button:hover {
  background-color: #0056b3; /* Darker blue on hover for feedback */
}

/* Styling for messages sent by the user */
.message.user {
  align-self: flex-end; /* Align to the right */
  background-color: #a0a0a0; /* Light green background for sent messages */
  color: #ffffff; /* Dark text for readability */
  padding: 10px;
  margin-bottom: 8px;
  border-radius: 12px;
  max-width: 80%; /* Limit message width */
  word-wrap: break-word; /* Ensure long words do not overflow */
}

/* Styling for messages from the assistant */
.message.assistant {
  align-self: flex-start; /* Align to the left */
  background-color: #63577a; /* Light grey background for received messages */
  color: #ffffff;
  padding: 20px;
  margin-bottom: 8px;
  border-radius: 12px;
  max-width: 80%;
  word-wrap: break-word;
}

/* Enhanced code block styling */
.code-block {
  background-color: #272822; /* Darker background for contrast */
  color: #f8f8f2; /* Light text for readability */
  border: none; /* Remove border for a cleaner look */
  padding: 12px; /* More padding for better spacing */
  margin-bottom: 10px;
  border-radius: 8px; /* Rounded corners for a modern feel */
  font-family: "Courier New", monospace;
  overflow-x: auto; /* Allow horizontal scrolling for long lines */
}
