/* src/modules/home.css */

/* Reset default margin and padding for the whole page */
body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
}

/* Example for large headings */
.large-font {
  font-family: "Atma";
  font-size: clamp(1.5rem, 2.5vw + 1rem, 4rem); /* Scales from 1.5rem to 4rem */
  line-height: 1.2;
  margin: 0;
  color: #333;
  text-align: left;
}

.dynamic-font {
  font-family: "Atma";
  font-size: clamp(
    1.2rem,
    2vw + 0.5rem,
    2.5rem
  ); /* Scales smaller for dynamic text */
  color: rgb(255, 230, 0);
}

/* CSS for the half-width-container class */
.half-width-container {
  width: 50%;
  padding: 2rem;
}

/* Create a class for full-screen sections */
.full-screen-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  position: relative;
  padding-right: 50%;
  z-index: 0;
}

/* Style the headings */
.full-screen-section h1 {
  /* font-size: 2rem; */
  margin-bottom: 10px;
  /* color: #333;  */
}

/* Style the paragraph text */
/* .full-screen-section p {
  font-size: 1.2rem;
  color: #666; 
} */

/* Add specific styles for each section */
.intro,
.b1,
.b2 {
  padding-bottom: 90px;
}

/* Style the navigation links */
.navigation {
  margin-top: 20px;
}

.navigation a {
  margin: 10px;
  font-size: 1.2rem;
  text-decoration: none;
  color: #007bff; /* Link color */
}

.navigation a:hover {
  text-decoration: underline;
}

.scroll-down-button {
  position: fixed;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  border: 2px solid #fff; /* Solid border for visibility */
  border-radius: 50%; /* Circular shape */
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent black background */
  color: #fff; /* White text color */
  font-size: 1.5rem; /* Adjust font size */
  padding: 10px;
  cursor: pointer;
  animation: bounce 2s infinite; /* Bouncing animation */
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px; /* Adjust width as needed */
  height: 40px; /* Adjust height as needed */
  font-size: 1.2rem; /* Adjust font size if necessary */
}

/* Bouncing animation keyframes */
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(-50%) translateY(0);
  }
  40% {
    transform: translateX(-50%) translateY(-30px);
  }
  60% {
    transform: translateX(-50%) translateY(-15px);
  }
}

.scroll-up-button {
  position: fixed;
  bottom: 100px;
  right: 50px; /* Adjust as needed */
  transform: translateX(-50%);
  border: 2px solid #fff; /* Solid border for visibility */
  border-radius: 50%; /* Circular shape */
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent black background */
  color: #fff; /* White text color */
  font-size: 1.5rem; /* Adjust font size */
  padding: 10px;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px; /* Adjust width as needed */
  height: 40px; /* Adjust height as needed */
  font-size: 1.2rem; /* Adjust font size if necessary */
}

.b1 h1,
.b2 h1,
.b3 h1 {
  font-size: clamp(1.8rem, 2vw + 1rem, 3rem);
  font-weight: bold; /* Bold font weight */
  text-align: center; /* Center the text */
}

.b1 p,
.b2 p,
.b3 p {
  line-height: 1.5; /* Space between lines for better readability */
}

.contentsection {
  width: 90%; /* Set the width to cover half of the screen width */
  margin: 0 auto; /* Center the container horizontally */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contentsection h1 {
  font-family: "Atma";
  font-size: clamp(1.8rem, 2vw + 1rem, 3rem); /* Adjust for section titles */
  line-height: 1.2;
  margin-bottom: 20px;
}

.contentsection p {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.contentsection ul {
  list-style-type: none;
  padding: 0;
}

.contentsection p,
.contentsection ul {
  font-family: "Atma";
  font-size: clamp(
    1rem,
    1.5vw + 0.5rem,
    2.5rem
  ); /* Adjust for general content text */
  line-height: 1.6;
}

.highlighted-role {
  padding: 20px 20px;
  margin: 4px 0;
  border-radius: 10px;
  font-weight: bold;
  font-size: clamp(1.5rem, 2vw + 0.5rem, 2.5rem);
  color: #333; /* Dark text color for readability */
  display: inline-block;
}

.delivered-item {
  padding: 10px 15px;
  margin: 8px 0;
  border-radius: 20px;
  font-weight: bold;
  color: #333; /* Dark text for readability */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: clamp(1.5rem, 1.5vw + 1rem, 1.5rem);
}

/* Ensure .contentsection has proper padding and alignment */
.contentsection {
  padding: 20px;
  text-align: center; /* Center the text if that's your design preference */
}

/* Images CSS */

.right-half {
  width: 50%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1;
}

/* Images CSS */

.image-container {
  position: fixed; /* Fixed to stay on screen during scroll */
  top: 0;
  right: 0;
  width: 50%; /* Adjust based on the desired width */
  height: 100vh; /* Full viewport height */
  overflow: hidden;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  transition: clip-path 0.8s ease-in-out; /* Apply clip-path for reveal effect */
  clip-path: inset(0); /* Start with full visibility */
}

/* Reveal from bottom to top when scrolling down */
.section-image.visible.down {
  clip-path: inset(100% 0 0 0); /* Initial state: fully hidden */
}

.section-image.visible.down {
  clip-path: inset(0 0 0 0); /* Fully visible */
}

/* Reveal from top to bottom when scrolling up */
.section-image.visible.up {
  clip-path: inset(0 0 100% 0); /* Initial state: hidden from the top */
}

.section-image.visible.up {
  clip-path: inset(0 0 0 0); /* Fully visible */
}

/* For outgoing images (hidden state) */
.section-image:not(.visible) {
  clip-path: inset(0); /* Default to no clipping when hidden */
  opacity: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes float {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(5px, -5px); /* Slightly smaller movement */
  }
  100% {
    transform: translate(0px, 0px);
  }
}

/* For smaller screens (e.g., phones)  */
@media (max-width: 600px) {
  .contentsection {
    width: 100%;
    margin-top: 3rem;
  }

  .large-font,
  .contentsection h1 {
    font-size: 1.5rem; /* Decrease heading size */
  }

  .dynamic-font {
    font-size: 1.2rem; /* Adjust dynamic text */
  }

  .bio-paragraph {
    font-size: 0.6rem; /* Smaller font size for mobile */
    line-height: 1.4;
  }

  /* Stack text and image sections vertically */
  .full-screen-section {
    flex-direction: column; /* Stack elements vertically */
    padding-right: 0; /* Remove any padding used for side-by-side layout */
  }

  /* Make both text and image containers full width */
  .half-width-container,
  .image-container {
    width: 100%; /* Full width for smaller screens */
    padding: 5rem; /* Adjust padding as needed for spacing */
  }
  .half-width-container {
    margin-top: 4rem;
  }

  /* Adjust the section image for responsive fit */
  .section-image {
    height: 60%;
    object-fit: cover; /* Ensures the image covers the area */
    border-radius: 8px; /* Optional: rounded corners for aesthetic */
    bottom: 0px;
  }
}

/* Medium screens (up to 1024px) */
@media (max-width: 1024px) {
  .contentsection {
    padding-top: 3rem;
    width: 100%;
  }

  .large-font,
  .contentsection h1 {
    font-size: 2rem; /* Slightly larger for tablets */
  }

  .dynamic-font {
    font-size: 1.5rem;
  }
}

/* CSS for Chatbot */

/* In home.css or a relevant stylesheet */
.open-chat-button {
  font-family: "Atma";
  position: fixed;
  bottom: 50px;
  right: 20px;
  padding: 4px 10px;
  color: #000000;
  border-width: 2px;
  border-style: solid;
  border-color: #000000;
  border-radius: 50px; /* High border-radius to achieve capsule shape */
  cursor: pointer;
  box-shadow: 4px 4px 0px rgba(0, 0, 0, 1); /* Darker and harder shadow */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.open-chat-button:hover {
  transform: translateY(-2px); /* Slight lift effect on hover */
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 1); /* Darker and harder shadow */
}

.chatbot-modal {
  position: fixed;
  bottom: 50px;
  right: 50px;
  /* width: 400px;
  height: 400px; */
  /* background-color: #fff; */
  border-radius: 10px;
  padding: 10px;
  overflow-y: auto;
}

.chatbot-modal-overlay {
  background-color: rgba(0, 0, 0, 0.3);
}

.close-chat-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}
