/* General styles */
.personalProjects {
  font-family: Arial, sans-serif;
  margin: 2rem;
}

.centered-content {
  text-align: center;
}

/* Container for each project */
.project-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Project image */
.project-img img {
  width: 500px;
  height: auto;
  border-radius: 8px;
}

/* Project information */
.project-info {
  flex: 1;
  margin-left: 1rem;
}

.project-info h2 {
  margin-bottom: 1rem;
}

.project-desc,
.project-usecase,
.project-tech {
  margin: 0.5rem 0;
}

/* Demo link */
.demo-link {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: #fff;
  border-radius: 4px;
  text-decoration: none;
}

.demo-link:hover {
  background-color: #0056b3;
}
