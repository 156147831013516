footer {
  position: fixed; /* Stick to the bottom of the viewport */
  bottom: 0; /* Align with the bottom */
  width: 100%; /* Full width */
  background-color: #45447d;
  height: 30px; /* Set a specific height */
  font-size: 0.7rem;
  color: #8c8c8c;
  padding: 5px;
  display: flex; /* Set up flexbox */
  justify-content: space-between; /* Distribute space between elements */
  align-items: center; /* Vertically center align */
}

.circle {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%; /* Changed from 10px to 50% to ensure it's a circle */
  cursor: pointer;
  border: black 1px solid;
  margin-left: 5px;
  margin-right: 5px;
  background: linear-gradient(
    to right,
    red 50%,
    blue 50%
  ); /* Example gradient */
}

.light {
  background: linear-gradient(to right, #74bbf5 50%, #e9eff7 50%);
}
.dark {
  background: linear-gradient(to right, #07a5a5 50%, #e6a00b 50%);
}
