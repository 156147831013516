h1 {
  margin: 20px;
  font-size: x-large;
}

h2 {
  margin-bottom: 0px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: large;
}

h3 {
  margin-bottom: 10px;
  margin-bottom: 10px;
}

h4 {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: small;
  margin: 10px;
  display: block;
}

.logobtn {
  height: 50px;
  width: 50px;
  background-color: #2a3f3f;
  border: none;
}

/* Accordion Items */
.accordion {
  font-size: 0.8em;
  width: 60vw;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 600px;
  overflow-y: auto;

  /* Add these */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.accordion::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}

/* Media query for smaller screens, adjust as needed */
@media (max-width: 768px) {
  .accordion {
    width: 100%; /* Make it full width */
    padding: 5px; /* Add some padding for spacing */
  }
}

.item {
  /* box-shadow: 0 0 30px rgba(0, 0, 0, 0.1); */
  padding: 20px 24px;
  padding-right: 48px;
  cursor: pointer;
  border-top: 2px solid #808080;
  border-bottom: 2px solid #808080;

  display: grid;
  grid-template-columns: auto 1fr auto auto;
  column-gap: 24px;
  row-gap: 32px;
  align-items: center;
}

.number {
  font-size: 1em;
  font-weight: 500;
  color: #ced4da;
}

.title,
.icon {
  font-size: 1.3em;
  font-weight: 500;
}

.date {
  font-size: 0.9em;
}

.content-box {
  grid-column: 2 / -1;
  padding-bottom: 16px;
  line-height: 1.6;
}

.content-box ul {
  color: #868e96;
  margin-left: 16px;
  margin-top: 16px;

  display: flex;
  flex-direction: column;
  gap: 12px;
}

/* ACCORDION OPEN STATE */
.open {
  border-top: 1px solid #1dc507;
}

.open .number,
.open .title {
  color: #1dc507;
}

.open .title {
  font-weight: bold;
}

.myCV-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 99%;
  margin: auto;
}

.cv-tab-container,
.cv-content-container {
  width: 100%; /* Takes full width on mobile */
  max-width: 95%; /* Takes up to 80% width on larger screens */
  margin: auto;
}

.cv-content-container ul {
  list-style-type: none;
  text-align: left;
  padding-left: 0;
  margin: 0;
}

.cv-content-container li {
  padding: 10px 20px; /* Added some horizontal padding */
  color: #fff; /* Dark grey text color */
  transition: background-color 0.3s ease; /* Smooth transition for hover effects */
}

.centered-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cv-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%; /* Full width */
  margin: auto;
  padding: 5px;
  padding-top: 25px;
}

.cv-tab-container {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap; /* Prevents items from wrapping onto the next line */
  overflow-x: auto; /* Adds scroll on x-axis if necessary */
  /* background-color: #1a242f; */
  -webkit-overflow-scrolling: touch; /* Enables momentum-based scrolling on iOS */
  border-bottom: 5px solid #8d8d8d; /* Line below the tabs */
  margin: 0;
  padding: 0;
  width: 100%;
}

/* Style the list items to look like tabs */
.cv-tab-container li {
  font-weight: bold;
  text-align: center;
  display: inline-block;
  list-style-type: none;
  background-color: #daa739;
  padding: 10px;
  margin: 5px;
  margin-bottom: 0;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
  /* flex: 1; */
  width: 30vw;
  min-width: 0; /* Reset the minimum width */
  flex-shrink: 0; /* Prevents shrinking */
  white-space: nowrap; /* Keeps text in a single line */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Show ellipsis when text overflows */
}

/* Active tab */
.cv-tab-container li.active {
  background-color: #fff;
  border-bottom: 2px solid transparent;
}

/* Media Query for small screens */
@media (max-width: 600px) {
  .myCV-container,
  .cv-content-container {
    width: 100%; /* Full width on mobile */
  }
}

@media (max-width: 600px) {
  .cv-tab-container {
    width: 100%; /* Full width on mobile */
  }
  .cv-tab-container li {
    padding: 8px;
    min-width: auto; /* Let it shrink on small screens */
  }
}

/* .skill {
  color: #000;
  list-style: none;
  flex-wrap: wrap;
  gap: 8px;
  margin: 16px;
  padding: 2px 12px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  max-width: 200px;
} */

/* .skill:hover,
.skill:focus {
  background: #eee;
} */

.skillslist {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 16px;
}

.skill {
  padding: 2px 12px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  color: #000;
  margin: 1%;
  white-space: nowrap;
}

.cv-sections {
  margin-bottom: 50px;
}

.skillsbox {
  width: 90%;
  margin: 40px;
  /* border: 1px solid gray; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.skills-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.btn-skills {
  background-color: #a0a0a0;
  padding: 1%;
  border-radius: 5px;
  border: none;
  margin: 1%;
  cursor: pointer;
  width: 150px;
}

.btn-skills:hover {
  background-color: white;
}

.btn-skills.active {
  background-color: #1dc507;
}
